import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Divider,
  Pagination,
  CircularProgress,
} from '@mui/material';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FlagIcon from '@mui/icons-material/Flag';
import { db } from '../firebase'; // Adjust according to your structure
import { collection, onSnapshot, doc, deleteDoc, updateDoc, getDocs } from 'firebase/firestore';
import formatTimestamp from '../constants/date-format';

const Comment = () => {
  const [comments, setComments] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [editCommentText, setEditCommentText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [flagOpen, setFlagOpen] = useState(false);
  const [openReplies, setOpenReplies] = useState({}); // State to manage replies visibility
  const [openFlags, setOpenFlags] = useState({}); // State to manage flags visibility
  const fetchComments = async () => {
    const insightsRef = collection(db, 'Insights');
    const unsubscribeInsights = onSnapshot(insightsRef, async (querySnapshot) => {
      const allComments = [];
      setComments([]); // Clear existing comments

      const insightsPromises = querySnapshot.docs.map(async (insightDoc) => {
        const insightData = insightDoc.data();
        const insightId = insightDoc.id;

        const commentsRef = collection(db, 'Insights', insightId, 'comments');
        const commentsSnapshot = await onSnapshot(commentsRef, async (commentSnapshot) => {
          const commentsData = [];

          for (const commentDoc of commentSnapshot.docs) {
            const commentData = {
              id: commentDoc.id,
              ...commentDoc.data(),
              insightTitle: insightData.title,
              insightId: insightId,
            };

            // Fetch replies for each comment
            const repliesRef = collection(db, 'Insights', insightId, 'comments', commentDoc.id, 'replies');
            const repliesSnapshot = await getDocs(repliesRef);
            commentData.replies = repliesSnapshot.docs.map(replyDoc => ({
              id: replyDoc.id,
              ...replyDoc.data(),
            }));

            // Fetch flags for each comment
            const flagsRef = collection(db, 'Insights', insightId, 'comments', commentDoc.id, 'flags');
            const flagsSnapshot = await getDocs(flagsRef);
            commentData.flags = flagsSnapshot.docs.map(flagDoc => ({
              id: flagDoc.id,
              ...flagDoc.data(),
            }));

            commentsData.push(commentData);
          }

          allComments.push(...commentsData);
        });

        return insightsPromises; // Return for insights processing
      });

      // Wait for all insights to be processed
      await Promise.all(insightsPromises);
      setComments(allComments); // Set comments after processing
      setLoading(false); // Set loading to false
    });

    return () => unsubscribeInsights(); // Cleanup on unmount
  };

  useEffect(() => {
    const handleFetchComments = async () => {
      try {
        await fetchComments(); // Fetch comments when the component mounts
      } catch (error) {
        console.error("Error fetching comments:", error);
        if (error.code === 'resource-exhausted') {
          console.log("Rate limit exceeded. Retrying after a delay...");
          await new Promise(resolve => setTimeout(resolve, 10000)); // Wait 10 seconds
          handleFetchComments(); // Retry fetching comments
        }
      }
    };

    handleFetchComments(); 
    
  }, []); 


  const handleDeleteComment = async (comment) => {
    const insightId = comment.insightId;
    await deleteDoc(doc(db, 'Insights', insightId, 'comments', comment.id));
    await fetchComments(); 
  };

  const handleEditComment = async () => {
    console.log("first");
    
    if (!editCommentText) return;
  
    const insightId = selectedComment.insightId;
    await updateDoc(doc(db, 'Insights', insightId, 'comments', selectedComment.id), {
      text: editCommentText,
    });
    setOpen(false);
    await fetchComments(); // Refresh comments after editing
  };

  const handleClickOpen = (comment) => {
    setSelectedComment(comment);
    setEditCommentText(comment.text);
    setOpen(true);
  };

  const handleFlagComment = async () => {
    const insightId = selectedComment.insightId;    
    await updateDoc(doc(db, 'Insights', insightId, 'comments', selectedComment.id), {
      flagged: true,
    });
    setFlagOpen(false);
    // await fetchComments(); // Refresh comments after flagging
  };

  const toggleReplies = (comment) => {
    setSelectedComment(comment);
    setOpenReplies(prev => ({ ...prev, [comment.id]: !prev[comment.id] }));
  };

  const toggleFlags = (comment) => {
    setSelectedComment(comment);
    setOpenFlags(prev => ({ ...prev, [comment.id]: !prev[comment.id] }));
  };

  const indexOfLastComment = currentPage * itemsPerPage;
  const indexOfFirstComment = indexOfLastComment - itemsPerPage;

  const filteredComments = loading ? [] : comments.filter(comment =>
    comment.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentComments = filteredComments.slice(indexOfFirstComment, indexOfLastComment);
  const totalPages = Math.ceil(filteredComments.length / itemsPerPage);

  return (
    <Box sx={{ width: '100%', maxWidth: 700, bgcolor: 'background.paper', margin: 'auto', mt: 4 }}>
      <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}>
        Comments
      </Typography>

      <Paper elevation={3} sx={{ padding: 2 }}>
        <TextField
          autoFocus
          margin="dense"
          label="Search Comment"
          type="text"
          fullWidth
          variant="outlined"
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <List sx={{ marginTop: 2 }}>
          {loading ? (
            // <ListItem>
              <CircularProgress></CircularProgress>
            // </ListItem>
          ) : currentComments.length > 0 ? (
            currentComments.map((comment) => (
              <Box key={comment.id}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                      <CommentIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`Comment on "${comment.insightTitle}"`}
                    secondary={
                      <>
                        <div>{comment.text}</div>
                        <div style={{ color: 'blue', fontWeight: 'bold' }}>{comment.username}</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ThumbUpIcon fontSize="small" style={{ color: 'green', marginRight: 4 }} /> {comment.likes.length || 0}
                          <ThumbDownIcon fontSize="small" style={{ color: 'red', marginRight: 4 }} /> {comment.dislikes.length || 0}
                          <AccessTimeIcon fontSize="small" style={{ marginLeft: 8 }} /> {formatTimestamp(comment.timestamp)}
                          <Button onClick={() => toggleFlags(comment)} color="warning" startIcon={<FlagIcon />} sx={{ ml: 2 }}>
                            Flags {comment.flags.length || 0}
                          </Button>
                          <Button onClick={() => handleClickOpen(comment)} color="primary" sx={{ ml: 2 }}>Edit</Button>
                          <Button onClick={() => handleDeleteComment(comment)} color="secondary" sx={{ ml: 2 }}>Delete</Button>
                          <Button onClick={() => toggleReplies(comment)} color="info" sx={{ ml: 2 }}>
                            {openReplies[comment.id] ? 'Hide Replies' : 'Show Replies'}
                          </Button>
                        </div>
                      </>
                    }
                  />
                </ListItem>
                <Divider />
                {/* Display Replies */}
                {openReplies[comment.id] && (
                  <Box sx={{ pl: 4 }}>
                    {comment.replies && comment.replies.length > 0 ? (
                      comment.replies.map(reply => (
                        <ListItem key={reply.id}>
                          <ListItemText primary={reply.text} secondary={reply.username} />
                        </ListItem>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">No replies found.</Typography>
                    )}
                  </Box>
                )}
                {/* Display Flags */}
                {openFlags[comment.id] && (
                  <Box sx={{ pl: 4 }}>
                    {!comment.flagged ? (
                      <Button onClick={() => setFlagOpen(true)} color="warning" startIcon={<FlagIcon />} sx={{ ml: 2 }}>Flag</Button>
                    ) : (
                      <div style={{ color: 'blue', fontWeight: 'bold' }}>Already Flagged by admin</div>
                    )}
                    {comment.flags && comment.flags.length > 0 ? (
                      comment.flags.map(flag => (
                        <ListItem key={flag.id}>
                          <ListItemText primary={`Flagged by: ${flag.username}`} secondary={`Reason: ${flag.reason || 'No reason specified'}`} />
                        </ListItem>
                      ))
                    ) : (
                      <Typography variant="body2" color="text.secondary">No flags found.</Typography>
                    )}
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No comments found." />
            </ListItem>
          )}
        </List>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
          sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
        />
      </Paper>

      {/* Edit Comment Dialog */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Edit Comment</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Comment"
            type="text"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            value={editCommentText}
            onChange={(e) => setEditCommentText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleEditComment} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      {/* Flag Confirmation Dialog */}
      <Dialog open={flagOpen} onClose={() => setFlagOpen(false)}>
        <DialogTitle>Confirm Flag</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to flag this comment?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFlagOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleFlagComment} color="primary">Flag</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Comment;