import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Paper,
  Divider,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Badge,
} from '@mui/material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { db } from '../firebase'; // Adjust the import according to your structure
import { collection, onSnapshot, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import formatTimestamp from '../constants/date-format';

const Question = () => {
  const [questions, setQuestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [editQuestionText, setEditQuestionText] = useState('');
  const [expandedQuestionId, setExpandedQuestionId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const questionsRef = collection(db, 'questions');

    const unsubscribeQuestions = onSnapshot(questionsRef, (querySnapshot) => {
      const questionsData = querySnapshot.docs.map(async (questionDoc) => {
        const questionData = {
          id: questionDoc.id,
          ...questionDoc.data(),
        };

        // Fetch replies for each question
        const repliesRef = collection(db, 'questions', questionDoc.id, 'replies');
        const repliesSnapshot = onSnapshot(repliesRef, (replySnapshot) => {
          questionData.replies = replySnapshot.docs.map(replyDoc => ({
            id: replyDoc.id,
            ...replyDoc.data(),
          }));
        });

        return questionData;
      });

      Promise.all(questionsData).then(setQuestions);
    });

    return () => unsubscribeQuestions();
  }, []);

  const handleDeleteQuestion = async (id) => {
    await deleteDoc(doc(db, 'questions', id));
  };

  const handleEditQuestion = async () => {
    if (!editQuestionText) return;
    try {
      await updateDoc(doc(db, 'questions', selectedQuestion.id), {
        title: editQuestionText,
      });
      setOpen(false);
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  const handleClickOpen = (question) => {
    setSelectedQuestion(question);
    setEditQuestionText(question.title);
    setOpen(true);
  };

  const handleRepliesOpen = (questionId) => {
    setExpandedQuestionId(prevId => (prevId === questionId ? null : questionId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Pagination logic
  const indexOfLastQuestion = currentPage * itemsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - itemsPerPage;

  // Filter questions based on search term
  const filteredQuestions = questions.filter(question =>
    question.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const currentQuestions = filteredQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);
  const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);

  return (
    <Box sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', margin: 'auto', mt: 4 }}>
      <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold' }}>
        Questions
      </Typography>

      {/* Search Field */}

      {/* Items Per Page Selector */}

      <Paper elevation={3} sx={{ padding: 2 }}>
      <TextField
        variant="outlined"
        placeholder="Search questions..."
        fullWidth
        sx={{ mb: 2 }}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

        <List sx={{ marginTop: 2 }}>
          {currentQuestions.map((question) => (
            <Box key={question.id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <QuestionAnswerIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={question.title}
                  secondary={
                    <>
                      <div>
                        <AccessTimeIcon fontSize="small" style={{ marginRight: 4 }} />
                        {formatTimestamp(question.timestamp)}
                      </div>
                      <Typography>{question.category}</Typography>
       
                   </>
                  }
                />
                <Button onClick={() => handleClickOpen(question)} color="primary">Edit</Button>
                <Button onClick={() => handleDeleteQuestion(question.id)} color="secondary">Delete</Button>
                <Button onClick={() => handleRepliesOpen(question.id)} color="primary">Replies</Button>
              </ListItem>
              <Divider />

              {/* Replies Section */}
              {expandedQuestionId === question.id && (
  <List sx={{ pl: 4, bgcolor: 'background.default', borderRadius: 1 }}>
    {question.replies && question.replies.length > 0 ? (
      question.replies.map((reply) => (
        <ListItem key={reply.id} sx={{ mb: 1, bgcolor: 'background.paper', borderRadius: 1 }}>
          {/* <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <QuestionAnswerIcon />
            </Avatar>
          </ListItemAvatar> */}
          <ListItemText
            primary={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{reply.text}</span>
              </div>
            }
            secondary={
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
                <AccessTimeIcon fontSize="small" style={{ marginRight: 4 }} />
                <span>{formatTimestamp(reply.timestamp)}</span>
              </div>
            }
          />
        </ListItem>
      ))
    ) : (
      <ListItem>
        <ListItemText primary="No replies" />
      </ListItem>
    )}
  </List>
)}
            </Box>
          ))}
        </List>

        {/* Pagination Controls */}
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
          sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}
        />
      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Question</DialogTitle>
        <DialogContent>
        <TextField
  autoFocus
  margin="dense"
  label="Question Title"
  multiline
  rows={4} 
  fullWidth
  variant="outlined"
  value={editQuestionText}
  onChange={(e) => setEditQuestionText(e.target.value)}
/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleEditQuestion} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Question;