import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Typography,
  circularProgressClasses,
  colors,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import { images } from "../assets";
import { Link, useNavigate } from "react-router-dom";
import Animate from "../components/common/Animate";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { InputAdornment, IconButton } from "@mui/material";
import { VisibilityOff, Visibility } from "@mui/icons-material";

const LoginPage = () => {
  const navigate = useNavigate();

  const [onRequest, setOnRequest] = useState(false);
  const [loginProgress, setLoginProgress] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const rememberMeValue = localStorage.getItem("rememberMe") === "true";
    setRememberMe(rememberMeValue);
  }, []);

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    localStorage.setItem("rememberMe", event.target.checked);
  };

  const handlePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const onSignin = async (e) => {
    e.preventDefault();
  
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
  
    setUsernameError('');
    setPasswordError('');
    setGeneralError('');
  
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setUsernameError('Valid email is required');
      return;
    }
  
    if (!password) {
      setPasswordError('Password is required');
      return;
    }
  
    setOnRequest(true);
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setIsLoggedIn(true);
      navigate("/dashboard"); // Navigate to dashboard page here
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
  
      if (errorCode === 'auth/invalid-email') {
        setUsernameError('Invalid email address');
      } else if (errorCode === 'auth/wrong-password') {
        setPasswordError('Incorrect password');
      } else {
        setGeneralError('Authentication failed. Please try again.');
      }
  
      setOnRequest(false);
    }
  
    const interval = setInterval(() => {
      setLoginProgress(prev => prev + 100 / 40);
    }, 50);
  
    setTimeout(() => {
      clearInterval(interval);
    }, 2000);
  };
  

  return (
    <Box
      position="relative"
      height="100vh"
      sx={{ "::-webkit-scrollbar": { display: "none" } }}
    >
      <Box
        sx={{
          position: "absolute",
          right: 0,
          height: "80%",
          width: "40%",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${images.loginBg})`,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: 0,
          height: "100%",
          width: isLoggedIn
            ? "100%"
            : { xl: "30%", lg: "40%", md: "50%", xs: "100%" },
          transition: "all 1s ease-in-out",
          bgcolor: colors.common.white,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            opacity: isLoggedIn ? 0 : 1,
            transition: "all 0.3s ease-in-out",
            height: "100%",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Box sx={{ textAlign: "center", p: 5 }}>
            <Animate type="fade" delay={0.5}>
              <img src={images.logo} alt="logo" height={100}></img>
            </Animate>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "::-webkit-scrollbar": { display: "none" },
            }}
          >
            <Animate type="fade" sx={{ maxWidth: 400, width: "100%" }}>
              <Box
                component="form"
                maxWidth={400}
                width="100%"
                onSubmit={onSignin}
              >
                <Stack spacing={3}>
                  <TextField
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    error={!!usernameError}
                    helperText={usernameError}
                  />
                  <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    error={!!passwordError}
                    helperText={passwordError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="success"
                    disabled={onRequest}
                  >
                    Sign In
                  </Button>
                  {generalError && (
                    <Typography color="error">{generalError}</Typography>
                  )}
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                          />
                        }
                        label="Remember me"
                      />
                    </FormGroup>
                    {/* <Typography color="error" fontWeight="bold">
                      <Link to="/PasswordResetPage">Forgot password?</Link>
                    </Typography> */}
                  </Stack>
                </Stack>
              </Box>
            </Animate>
          </Box>
          {onRequest && (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                bgcolor: colors.common.white,
                zIndex: 1000,
              }}
            >
              <Box position="relative">
                <CircularProgress
                  variant="determinate"
                  sx={{ color: colors.grey[200] }}
                  size={100}
                  value={100}
                />
                <CircularProgress
                  variant="determinate"
                  disableShrink
                  value={loginProgress}
                  size={100}
                  sx={{
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                    position: "absolute",
                    left: 0,
                    color: colors.green[600],
                  }}
                />
              </Box>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
