import React, { useState, useEffect } from 'react';
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail, fetchSignInMethodsForEmail } from "firebase/auth";
import { auth } from "../firebase";

const PasswordResetPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [networkError, setNetworkError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const checkEmailRegistered = async (email) => {
    try {
      console.log("Checking if email is registered:", email);
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      console.log("Sign-in methods for email:", signInMethods);
      return signInMethods.length > 0;
    } catch (error) {
      console.error('Error checking if email is registered:', error);
      if (error.code === 'auth/network-request-failed') {
        setNetworkError(true);
      }
      return false;
    }
  };

  const onPasswordReset = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email.");
      return;
    }

    try {
      const isRegistered = await checkEmailRegistered(email);

      if (!isRegistered) {
        setError("Email is not registered.");
        return;
      }

      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError(error.message);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      sx={{ bgcolor: '#f5f5f5', p: 3 }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
        Reset Your Password
      </Typography>
      {emailSent ? (
        <Typography>
          An email has been sent to {email} with instructions to reset your password.
        </Typography>
      ) : (
        <Box component="form" onSubmit={onPasswordReset} sx={{ width: '100%', maxWidth: 400, bgcolor: 'white', p: 3, borderRadius: 2, boxShadow: 3 }}>
          <Stack spacing={2}>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              variant="outlined"
              error={!!emailError}
              helperText={emailError}
              onBlur={() => {
                if (!validateEmail(email)) {
                  setEmailError("Please enter a valid email.");
                } else {
                  setEmailError("");
                }
              }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Send Password Reset Email
            </Button>
            {error && <Typography color="error">{error}</Typography>}
            {networkError && <Typography color="error">Network error: Please check your internet connection.</Typography>}
          </Stack>
        </Box>
      )}
      <Button onClick={() => navigate(-1)} sx={{ mt: 2, color: 'primary.main' }}>
        Back to Login
      </Button>
    </Box>
  );
};

export default PasswordResetPage;
