import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  InputAdornment,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, MenuItem, Chip, Checkbox, ListItemText,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Person as PersonIcon,
  Category as CategoryIcon,
  Star as StarIcon,
  Assignment as AssignmentIcon,
  Build as BuildIcon,
  Phone as PhoneIcon,
  Home as HomeIcon,
  LocationCity as LocationCityIcon,
  Map as MapIcon,
  Place as PlaceIcon,
  SupervisorAccount as SupervisorAccountIcon,
  ContactPhone as ContactPhoneIcon,
  Email as EmailIcon,
  Telegram as TelegramIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { doc, setDoc, collection, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const AddSearchListes = () => {
  const [listes, setListes] = useState([]);
  const [newListe, setNewListe] = useState({
    name: '',
    type: '',
    goal: '',
    mission: '',
    services: [], // Initialize as an empty array
    phone: '',
    address: '',
    district: '',
    zone: '',
    location: '',
    representative: '',
    repPhone: '',
    email: '',
    telegram: '',
  });
  

  const health = 'Health center';
const children = 'Children services';
const travel = 'Travel Agency';
const social = 'Social Work';
const sefty = 'Safty inistitute'; // Typo corrected from "sefty"
const religious = 'religious inistitute';
const bussiness = 'Business center'; // Typo corrected from "bussiness"
const educationalInstitution = 'Educational Institution';

  
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [listToDelete, setListToDelete] = useState(null);

  useEffect(() => {
    const fetchListes = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'institutes'));
        const fetchedListes = [];
        querySnapshot.forEach((doc) => {
          fetchedListes.push({ id: doc.id, ...doc.data() });
        });
        setListes(fetchedListes);
      } catch (error) {
        console.error('Error fetching documents: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListes();
  }, []);

  const handleAddListe = async () => {
    setIsSaving(true);
    try {
      if (isEditing && currentId) {
        const docRef = doc(db, 'institutes', currentId);
        await updateDoc(docRef, newListe);
        setListes(listes.map(liste => liste.id === currentId ? { id: currentId, ...newListe } : liste));
        setIsEditing(false);
        setCurrentId(null);
      } else {
        const docRef = doc(collection(db, 'institutes'));
        await setDoc(docRef, newListe);
        setListes([...listes, { id: docRef.id, ...newListe }]);
      }
      setNewListe({
        name: '',
        type: '',
        goal: '',
        mission: '',
        services: [],
        phone: '',
        address: '',
        district: '',
        zone: '',
        location: '',
        representative: '',
        repPhone: '',
        email: '',
        telegram: '',
      });
    } catch (error) {
      console.error('Error adding document: ', error);
    }
    setIsSaving(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'type') {
      setNewListe({
        ...newListe,
        [name]: value,
      });
    }
    // Handle array of services as tags
    else if (name === 'services') {
      // Convert value to an array if it's not already
      const selectedServices = Array.isArray(value) ? value : [value];
      setNewListe({
        ...newListe,
        services: selectedServices,
      });
    }
    // Handle other input fields
    else {
      setNewListe({
        ...newListe,
        [name]: value,
      });
    }
  };
  
  const handleDeleteListe = (id) => {
    setListToDelete(id);
    setOpenDeleteDialog(true);
  };
  
  // Add this function to handle deleting the item after confirmation
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'institutes', listToDelete));
      setListes(listes.filter((liste) => liste.id !== listToDelete));
      console.log("Item deleted successfully:", listToDelete);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
    setOpenDeleteDialog(false);
    setListToDelete(null);
  };
  
  // Add this function to handle closing the delete dialog
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setListToDelete(null);
  };


  const handleEditListe = (index) => {
    const listeToEdit = listes[index];
    setNewListe(listeToEdit);
    setIsEditing(true);
    setCurrentId(listeToEdit.id);
  };

  const writeListes = () => {
    return listes.map((liste, index) => (
      <Accordion
        key={index}
        sx={{
          marginBottom: 1,
          borderRadius: 2,
          borderColor: 'secondary.main',
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ paddingTop: 1, paddingBottom: 1 }}
        >
          <Typography>{liste.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ width: '100%', height: '100%' }}>
            <Typography>
              <strong>Type:</strong> {liste.type}
            </Typography>
            <Typography>
              <strong>Goal:</strong> {liste.goal}
            </Typography>
            <Typography>
              <strong>Mission:</strong> {liste.mission}
            </Typography>
            <Typography>
              <strong>Services:</strong> {liste.services}
            </Typography>
            <Typography>
              <strong>Phone:</strong> {liste.phone}
            </Typography>
            <Typography>
              <strong>Address:</strong> {liste.address}
            </Typography>
            <Typography>
              <strong>District:</strong> {liste.district}
            </Typography>
            <Typography>
              <strong>Zone:</strong> {liste.zone}
            </Typography>
            <Typography>
              <strong>Location:</strong> {liste.location}
            </Typography>
            <Typography>
              <strong>Representative:</strong> {liste.representative}
            </Typography>
            <Typography>
              <strong>Rep Phone:</strong> {liste.repPhone}
            </Typography>
            <Typography>
              <strong>Email:</strong> {liste.email}
            </Typography>
            <Typography>
              <strong>Telegram:</strong> {liste.telegram}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <IconButton
              aria-label='edit'
              onClick={() => handleEditListe(index)}
              sx={{
                color: 'primary.main',
                '&:hover': {
                  backgroundColor: 'primary.light',
                  color: 'white',
                },
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
  aria-label='delete'
  onClick={() => handleDeleteListe(liste.id)}
  sx={{
    color: 'secondary.main',
    '&:hover': {
      backgroundColor: 'secondary.light',
      color: 'white',
    },
  }}
>
  <DeleteIcon />
</IconButton>
          </Box>
        </AccordionDetails>
      </Accordion>
    ));
  };


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        p: 3,
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '50%',
          bgcolor: 'background.transparent',
          p: 2,
          borderRadius: 0,
        }}
      >
        <Typography variant='h4' component='div' gutterBottom>
          Saved Listes
        </Typography>
        <Box>
          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            writeListes()
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: '45%',
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 2,
          ml: 3,
        }}
      >
        <Typography variant='h4' component='div' gutterBottom>
          {isEditing ? 'Edit Search List' : 'Add Search List'}
        </Typography>
        <FormControl variant='outlined' fullWidth>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id='name'
                label='Name'
                variant='outlined'
                value={newListe.name}
                onChange={handleChange}
                name='name'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PersonIcon />
                      </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
            <TextField
  id='type'
  select
  label='Type'
  variant='outlined'
  value={newListe.type}
  onChange={handleChange}
  name='type'
  fullWidth
  InputProps={{
    startAdornment: (
      <InputAdornment position='start'>
        <CategoryIcon />
      </InputAdornment>
    ),
  }}
>
  {[
    health,
    children,
    travel,
    social,
    sefty,
    religious,
    bussiness,
    educationalInstitution,
  ].map((option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ))}
</TextField>

            </Grid>
            <Grid item xs={6}>
              <TextField
                id='goal'
                label='Goal'
                variant='outlined'
                value={newListe.goal}
                onChange={handleChange}
                name='goal'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <StarIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='mission'
                label='Mission'
                variant='outlined'
                value={newListe.mission}
                onChange={handleChange}
                name='mission'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <AssignmentIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
            <TextField
  id='services'
  label='Services'
  variant='outlined'
  value={newListe.services}
  onChange={handleChange}
  name='services'
  fullWidth
  select
  SelectProps={{
    multiple: true,
    renderValue: (selected) => (
      <div>
        {selected.map((value) => (
          <Chip key={value} label={value} style={{ margin: 2 }} />
        ))}
      </div>
    ),
  }}
  InputProps={{
    startAdornment: (
      <InputAdornment position='start'>
        <BuildIcon />
      </InputAdornment>
    ),
  }}
>
  {['Help', 'Rent', 'Buy& Sell', 'Service 4'].map((service) => (
    <MenuItem key={service} value={service}>
      <Checkbox checked={newListe.services.indexOf(service) > -1} />
      <ListItemText primary={service} />
    </MenuItem>
  ))}
</TextField>

            </Grid>
            <Grid item xs={6}>
              <TextField
                id='phone'
                label='Phone'
                variant='outlined'
                value={newListe.phone}
                onChange={handleChange}
                name='phone'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='address'
                label='Address'
                variant='outlined'
                value={newListe.address}
                onChange={handleChange}
                name='address'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <HomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='district'
                label='District'
                variant='outlined'
                value={newListe.district}
                onChange={handleChange}
                name='district'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <LocationCityIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='zone'
                label='Zone'
                variant='outlined'
                value={newListe.zone}
                onChange={handleChange}
                name='zone'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MapIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='location'
                label='Location'
                variant='outlined'
                value={newListe.location}
                onChange={handleChange}
                name='location'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PlaceIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='representative'
                label='Representative'
                variant='outlined'
                value={newListe.representative}
                onChange={handleChange}
                name='representative'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SupervisorAccountIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='repPhone'
                label='Rep Phone'
                variant='outlined'
                value={newListe.repPhone}
                onChange={handleChange}
                name='repPhone'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <ContactPhoneIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='email'
                label='Email'
                variant='outlined'
                value={newListe.email}
                onChange={handleChange}
                name='email'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='telegram'
                label='Telegram'
                variant='outlined'
                value={newListe.telegram}
                onChange={handleChange}
                name='telegram'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <TelegramIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
              
             
              
                
                color="secondary"
                variant='contained'
               
                onClick={handleAddListe}
                fullWidth
                startIcon={isSaving ? <CheckCircleIcon /> : null}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Dialog
  open={openDeleteDialog}
  onClose={handleCloseDeleteDialog}
  aria-labelledby='alert-dialog-title'
  aria-describedby='alert-dialog-description'
>
  <DialogTitle id='alert-dialog-title'>Confirm Delete</DialogTitle>
  <DialogContent>
    <DialogContentText id='alert-dialog-description'>
      Are you sure you want to delete this item?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDeleteDialog} color='primary'>
      Cancel
    </Button>
    <Button onClick={handleDelete} color='secondary' autoFocus>
      Delete
    </Button>
  </DialogActions>
</Dialog>
    </Box>
  );
};

export default AddSearchListes;
