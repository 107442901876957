// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from '../routes/UserContext';
import AccessDenied from './AccessDinied';

const ProtectedRoute = ({ allowedRoles }) => {
  const user = useUser();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (allowedRoles.includes(user.role)) {
    return <Outlet />;
  } else {
    return <AccessDenied />;
  }
};

export default ProtectedRoute;