// src/components/AccessDenied.js
import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const AccessDenied = () => {
  return (
    <Container>
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="body1">
          You do not have permission to access this page.
        </Typography>
      </Box>
    </Container>
  );
};

export default AccessDenied;