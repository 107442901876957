import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import Documents from '@mui/icons-material/Dock';
import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Typography, colors, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { images } from "../../assets";
import Animate from "./Animate";
import { Comment, InsightsOutlined, QuestionMark, SearchRounded } from '@mui/icons-material';
import { Link , useLocation} from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';



const menus = [
  {
    title: "Inbox",
    icon: <MailOutlinedIcon />,
    state: "inbox"
  },
  {
    title: "Overview",
    icon: <DashboardCustomizeOutlinedIcon />,
    state: "overview"
  },
  {
    title: "Register",
    icon: <AdminPanelSettingsIcon />,
    state: "notification"
  }
];

const serviceMenus = [
  {
    title: "Add Search Listes",
    icon: <SearchRounded />,
    state: "Listes"
  },
  
  {
    title: "Community Insight",
    icon: <InsightsOutlined />,
    state: "Insight"
  },
  {
    title: "Document Upload",
    icon: <Documents />,
    state: "Doc"
  },
  {
    title: "Comment",
    icon: <Comment />,
    state: "comment"
  },
  {
    title: "Question & Answers",
    icon: <QuestionMark />,
    state: "questions"
  },

];


const Sidebar = ({ sidebarWidth }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const path = location.pathname.split('/')[2];
  const activeState = path || "overview";
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

// Get the current path

  const MenuItem = (props) => {
    const isActive = props.item.state === activeState; // Check if the current item is active

    return (
      <ListItem key={props.index} disableGutters disablePadding sx={{ py: 0.5 }}>
        <ListItemButton component={Link} to={`/dashboard/${props.item.state}`} sx={{
          borderRadius: "10px",
          bgcolor: isActive ? colors.green[600] : "",
          color: isActive ? colors.common.white : "",
          "&:hover": {
            bgcolor: isActive ? colors.green[600] : "",
            color: isActive ? colors.common.white : "",
          }
        }}>
          <ListItemIcon sx={{
            minWidth: "40px",
            color: isActive ? colors.common.white : ""
          }}>
            {props.item.icon}
          </ListItemIcon>
          <ListItemText primary={
            <Typography fontWeight={600}>
              {props.item.title}
            </Typography>
          } />
        </ListItemButton>
      </ListItem>
    );
  };

  const drawer = (
    <Box
      padding={3}
      paddingBottom={0}
      display="flex"
      flexDirection="column"
      height="100vh"
      sx={{
        "::-webkit-scrollbar": {
          display: "none"
        }
      }}
    >
      {/* logo */}
      <Box sx={{ textAlign: "center", mb: 2 }}>
        <Animate type="fade" delay={1}>
          <img src={images.logo} alt="logo" height={80} />
        </Animate>
      </Box>
      {/* logo */}

      <Animate sx={{ flexGrow: 1 }}>
        <Paper
          elevation={0}
          square
          sx={{
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            p: 2,
            height: "100%",
            boxShadow: "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px"
          }}
        >
          {/* menu group 1 */}
          <List>
            {menus.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
                isActive={item.state === activeState}
              />
            ))}
          </List>

          <List>
            <ListItem>
              <Typography fontWeight={600} mt={1} color={colors.grey[600]}>
                Configure
              </Typography>
            </ListItem>
            {serviceMenus.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
                isActive={item.state === activeState}
              />
            ))}
          </List>
        
        </Paper>
      </Animate>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { md: sidebarWidth },
        flexShrink: { md: 0 }
      }}
    >
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          "& .MuiDrawer-paper": {
            boxSizing: 'border-box',
            width: sidebarWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          "& .MuiDrawer-paper": {
            boxSizing: 'border-box',
            width: sidebarWidth,
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};


export default Sidebar;