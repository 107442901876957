import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  TextField,
  Button,
  Grid,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { db, storage } from "../firebase";
import { doc, setDoc, collection, onSnapshot, query, deleteDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const categories = [
  { key: 'news', label: 'News' },
  { key: 'article', label: 'Article' },
  { key: 'tips', label: 'Tips' },
  // add more categories as needed
];

const CommunityInsight = () => {
  const [insights, setInsights] = useState([]);
  const [newInsight, setNewInsight] = useState({
    title: "",
    content: "",
    images: [],
    imageUrls: [],
    author: "",
    date: "",
    category: "",
    youtubeLink: "",
  });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editInsightId, setEditInsightId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [insightToDelete, setInsightToDelete] = useState(null);

  useEffect(() => {
    const insightsRef = query(collection(db, "Insights"));
    const unsubscribe = onSnapshot(insightsRef, (querySnapshot) => {
      const insightsData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setInsights(insightsData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewInsight((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = async (event) => {
    const files = Array.from(event.target.files);
    const imageUrls = await uploadImagesAndGetUrls(files);
    setNewInsight((prevState) => ({
      ...prevState,
      images: files,
      imageUrls,
      youtubeLink: "", // clear youtubeLink when images are added
    }));
  };

  const handleYouTubeLinkChange = (event) => {
    setNewInsight((prevState) => ({
      ...prevState,
      youtubeLink: event.target.value,
      images: [],
      imageUrls: [event.target.value], // store the YouTube link in imageUrls
    }));
  };

  const uploadImagesAndGetUrls = async (images) => {
    const uploadPromises = images.map(async (image) => {
      const storageRef = ref(storage, `images/${image.name}`);
      await uploadBytes(storageRef, image);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    });
    return Promise.all(uploadPromises);
  };

  const handleAddOrEditInsight = async () => {
    let imageUrls = newInsight.imageUrls;

    if (newInsight.images.length > 0) {
      imageUrls = await uploadImagesAndGetUrls(newInsight.images);
    }

    const newInsightData = {
      ...newInsight,
      images: imageUrls,
      date: new Date().toISOString().slice(0, 10), // add the current date
    };

    try {
      if (isEditing) {
        await setDoc(doc(db, "Insights", editInsightId), newInsightData);
        console.log("Insight edited successfully:", newInsightData.title);
        setInsights((prevInsights) =>
          prevInsights.map((insight) =>
            insight.id === editInsightId ? { ...newInsightData, id: editInsightId } : insight
          )
        );
      } else {
        const newDocRef = doc(collection(db, "Insights"));
        await setDoc(newDocRef, newInsightData);
        console.log("Insight added successfully:", newInsightData.title);
        setInsights((prevInsights) => [...prevInsights, { ...newInsightData, id: newDocRef.id }]);
      }
    } catch (error) {
      console.error("Error adding/editing insight:", error);
    }

    setNewInsight({ title: "", content: "", images: [], imageUrls: [], author: "", category: "", youtubeLink: "" });
    setIsEditing(false);
    setEditInsightId(null);
  };

  const handleEdit = (insight) => {
    setNewInsight({
      title: insight.title,
      content: insight.content,
      images: [],
      imageUrls: insight.images,
      author: insight.author,
      date: insight.date,
      category: insight.category,
      youtubeLink: insight.images[0]?.includes("youtube.com") ? insight.images[0] : "",
    });
    setIsEditing(true);
    setEditInsightId(insight.id);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "Insights", insightToDelete));
      setInsights((prevInsights) => prevInsights.filter((insight) => insight.id !== insightToDelete));
      console.log("Insight deleted successfully:", insightToDelete);
    } catch (error) {
      console.error("Error deleting insight:", error);
    }
    setOpenDeleteDialog(false);
    setInsightToDelete(null);
  };

  const openDeleteConfirmation = (id) => {
    setOpenDeleteDialog(true);
    setInsightToDelete(id);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setInsightToDelete(null);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", p: 3 }}>
      <Box sx={{ width: "50%", pr: 2 }}>
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{
            color: "#333333",
            fontWeight: "normal",
            borderBottom: "1px solid",
            paddingBottom: "0.1rem",
            textTransform: "uppercase",
            letterSpacing: "0.1rem",
            textAlign: "center",
            borderRadius: 3,
            border: 0,
            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .1)",
            padding: "0 30px",
          }}
        >
          Community Insights
        </Typography>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
            <CircularProgress />
          </Box>
        ) : insights.length > 0 ? (
          <List component="nav" aria-label="community insights">
            {insights.map((insight) => (
              <Accordion
                key={insight.id}
                sx={{
                  marginBottom: 2,
                  borderRadius: 2,
                  borderColor: "secondary.main",
                  borderWidth: 1,
                  borderStyle: "solid",
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{insight.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2" color="textPrimary" sx={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    Description: {insight.content}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Author: {insight.author}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Date: {insight.date}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Category: {insight.category}
                  </Typography>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      {insight.imageUrls?.map((url, index) => (
                        <Box key={index} sx={{ width: 'calc(25% - 8px)', margin: '4px', borderRadius: '10px', overflow: 'hidden', border: '2px solid #ccc' }}>
                          {url.includes("youtube.com") ? (
                            <iframe
                              width="100%"
                              height="200px"
                              src={url.replace("watch?v=", "embed/")}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title={`YouTube video ${index}`}
                            ></iframe>
                          ) : (
                            <img
                              src={url}
                              alt={`insight ${index}`}
                              style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: "8px",
                                objectFit: "cover",
                              }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "path/to/placeholder.png";
                                console.log("Error loading image URL:", url);
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                    <IconButton color="primary" onClick={() => handleEdit(insight)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => openDeleteConfirmation(insight.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </List>
        ) : (
          <Typography variant="body1" component="div" sx={{ p: 2 }}>
            No insights available.
          </Typography>
        )}
      </Box>
      <Box sx={{ width: "50%", pl: 2 }}>
        <Typography
          variant="h4"
          component="div"
          gutterBottom
          sx={{
            color: "#333333",
            fontWeight: "normal",
            borderBottom: "1px solid",
            paddingBottom: "0.1rem",
            textTransform: "uppercase",
            letterSpacing: "0.1rem",
            textAlign: "center",
            borderRadius: 3,
            border: 0,
            boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .1)",
            padding: "0 30px",
          }}
        >
          Post a Community Insight
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              name="title"
              value={newInsight.title}
              onChange={handleChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GroupIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Author"
              variant="outlined"
              name="author"
              value={newInsight.author}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                name="category"
                value={newInsight.category}
                onChange={handleChange}
                label="Category"
              >
                {categories.map((category) => (
                  <MenuItem key={category.key} value={category.key}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              variant="outlined"
              name="content"
              value={newInsight.content}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              fullWidth
              startIcon={<PhotoCameraIcon />}
            >
              Upload Images
              <input
                type="file"
                hidden
                multiple
                onChange={handleImageChange}
                disabled={!!newInsight.youtubeLink} // disable if YouTube link is provided
              />
            </Button>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
              {newInsight.imageUrls?.map((url, index) => (
                <Box key={index} sx={{ width: 'calc(25% - 8px)', margin: '4px', borderRadius: '10px', overflow: 'hidden', border: '2px solid #ccc' }}>
                  <img
                    src={url}
                    alt={`insight ${index}`}
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '8px',
                      objectFit: 'cover',
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = 'path/to/placeholder.png';
                      console.log('Error loading image URL:', url);
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="YouTube Link"
              variant="outlined"
              name="youtubeLink"
              value={newInsight.youtubeLink}
              onChange={handleYouTubeLinkChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <YouTubeIcon />
                  </InputAdornment>
                ),
              }}
              disabled={newInsight.images.length > 0} // disable if images are provided
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleAddOrEditInsight}
              fullWidth
              color="secondary"
            >
              {isEditing ? "Edit Insight" : "Post Insight"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this insight? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CommunityInsight;
