// src/routes/index.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppLayout from "../components/layout/AppLayout";
import LoginPage from "../pages/LoginPage";
import PasswordResetPage from "../pages/PasswordResetPage";
import MainLayout from "../components/layout/MainLayout";
import DashboardPage from "../pages/DashboardPage";
import Inbox from '../pages/Inbox';
import Notification from '../pages/Notification';
import AddSearchListes from '../pages/AddSearchListes';
import CommunityInsight from '../pages/CommunityInsight';
import Comment  from "../pages/Comment";
import Question  from "../pages/Question";

import NotFound from "../components/Notfound";
import ProtectedRoute from '../components/ProtectedRoute';
import { UserProvider } from './UserContext';
import DocumentUpload from "../pages/docup";

const AppRouter = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<LoginPage />} />
            <Route path="PasswordResetPage" element={<PasswordResetPage />} />
            <Route path="dashboard" element={<MainLayout />}>
              <Route index element={<DashboardPage />} />
              <Route element={<ProtectedRoute allowedRoles={['admin', 'guest']} />}>
                <Route path="Listes" element={<AddSearchListes />} />
                <Route path="Insight" element={<CommunityInsight />} />
                <Route path="Doc" element= {<DocumentUpload/>} />
                <Route path="comment" element={<Comment />} />
                <Route path="questions" element={<Question />} /> 
                <Route path="overview" element={<DashboardPage />} />
                <Route path="inbox" element={<Inbox />} />
              </Route>
              <Route element={<ProtectedRoute allowedRoles={['admin']} />}>

                <Route path="notification" element={<Notification />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default AppRouter;