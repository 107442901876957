// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Change to your primary color
    },
    secondary: {
      main: '#dc004e', // Change to your secondary color
    },
    background: {
      default: '#f4f6f8', // Background color
      paper: '#fff', // Paper background color
    },
    divider: '#ddd', // Divider color
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  shape: {
    borderRadius: 8, // Global border radius
  },
  shadows: [
    'none',
    '0px 1px 3px rgba(0,0,0,0.12), 0px 1px 2px rgba(0,0,0,0.24)',
    '0px 1px 5px rgba(0,0,0,0.12), 0px 1px 3px rgba(0,0,0,0.24)',
    '0px 3px 6px rgba(0,0,0,0.1)',
    '0px 10px 20px rgba(0,0,0,0.12)', // Example for shadow[4]
    // Add more shadows as needed
  ],
});

export default theme;
