import { initializeApp } from "firebase/app";
import { getFirestore, initializeFirestore, persistentLocalCache } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Import getStorage

const firebaseConfig = {
  apiKey: "AIzaSyCmu6dXbG7qQ8oitLSgmhRhkKe-p7b7JhI",
  authDomain: "adoye-b8e40.firebaseapp.com",
  projectId: "adoye-b8e40",
  storageBucket: "adoye-b8e40.appspot.com",
  messagingSenderId: "521202490294",
  appId: "1:521202490294:web:d95cc6dd197ad9c5c83f17",
  measurementId: "G-JV7R5NZYFF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache()
});
const storage = getStorage(app); // Initialize Firebase Storage

export { app, analytics, auth, db, storage };
