export const images = {
  loginBg: require("./images/L_a.png"),
  summaryImages: {
    totalBook: require("./images/users.png"),

    cancel: require("./images/search.png")
  },
  userProfile: require("./images/7309681.jpg"),

  logo: require("./images/L_a.png")
};