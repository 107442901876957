import React, { useState, useEffect } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
  Divider,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { db } from '../firebase';
import { collection, onSnapshot } from 'firebase/firestore';

const Inbox = () => {
  const [open, setOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const messagesRef = collection(db, 'messages');
    const unsubscribe = onSnapshot(messagesRef, (querySnapshot) => {
      const messagesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setMessages(messagesData);
    });

    return () => unsubscribe();
  }, []);

  const handleClickOpen = (message) => {
    setSelectedMessage(message);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper', margin: 'auto', mt: 4 }}>
      <Typography variant="h4" component="div" gutterBottom sx={{ textAlign: 'center', mb: 2, fontWeight: 'bold', color: 'primary.main' }}>
        Inbox
      </Typography>
      <Paper elevation={3}>
        <List component="nav" aria-label="main mailbox folders">
          {messages.map((message, index) => (
            <Box key={message.id}>
              <ListItem button onClick={() => handleClickOpen(message)} sx={{ '&:hover': { bgcolor: 'primary.light' } }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <MailIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={message.subject} secondary={`${message.name} - ${message.message}`} />
              </ListItem>
              {index < messages.length - 1 && <Divider variant="inset" component="li" />}
            </Box>
          ))}
        </List>
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ bgcolor: 'primary.main', color: 'white' }}>{selectedMessage?.subject}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1"><strong>Name:</strong> {selectedMessage?.name}</Typography>
            <Typography variant="body1"><strong>Phone Number:</strong> {selectedMessage?.Phone_number}</Typography>
            <Typography variant="body1"><strong>Message:</strong> {selectedMessage?.message}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Inbox;
